.Navbar {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.Navbar-bg {
  background-color: rgba(0, 0, 0, 1);
  transition: 0.5s;
}

.Navbar > .nav-logo {
  height: 100px;
  width: 120px;
  color: #ffffff;
}

.Navbar > .nav-items > a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
}

.Navbar > .nav-items > a:hover {
  opacity: 1;
  color: #ff0066;
}

.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
  background-color: black;
  height: 2px;
  width: 70%;
  background-color: #ff0066;
  position: absolute;
  bottom: -3px;
  left: 1px;
}

.Navbar > .nav-toggle {
  display: none;
}

@media (max-width: 700px) {
  .Navbar > .nav-items {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    transform: translateX(100%);
    transition: transform 0.3s;
    z-index: 100;
    box-sizing: border-box;
    padding-top: 100px;
    text-align: right;

  }

  .Navbar > .nav-items > a::before {
    background: transparent;
  }
  .Navbar > .nav-items > a {
    color: black;
  }
  .Navbar > .nav-items.open {
    transform: translateX(0);
    
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
    z-index: 200;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
    background-color: black;
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
    background-color: black;
  }
}

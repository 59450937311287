.part {
  width: 100%;
  height: 100vh;
  position: relative;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

.intro_image {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.svg_logo {
  max-width: 90%;
  margin-left: calc(50% - 445px);
}

.particle_div {
  position: absolute;
  top: 10px;
  width: 100%;
}

@media screen and (max-width: 730px) {
  .svg_logo {
    max-width: 90%;
    margin-left: 5%;
  }
  .particle_div {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 90%;
    height: 50vh;
  }
}

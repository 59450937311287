@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  background-color: black;
}

.load_gif {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 620px) {
  .load_gif_img {
    width: 20%;
    height: 50%;
  }
}

@media screen and (max-width: 619px) {
  .load_gif_img {
    width: 50%;
    height: 35%;
  }
}
